import { createConstEnum } from '~/common/enum';

/**
 * A utility type that extracts the value type of a const enum-like object.
 * The type parameter `T` represents a const enum-like object with readonly values.
 *
 * @typeparam T - A const enum-like object with readonly values. This will
 * default to a simple Readonly<Record<keyof T, infer V>> if not provided.
 *
 * @returns The value type of the const enum-like object.
 *
 * @example
 * const MyEnumObject = createConstEnum({
 *   A: 'a',
 *   B: 'b',
 *   C: 'c',
 * });
 *
 * type MyEnumType = EnumType<typeof MyEnumObject>;
 * // MyEnumType type is 'a' | 'b' | 'c'
 */
export type EnumType<T> = T extends Readonly<Record<keyof T, infer V>> ? V : never;

export const ShippingPlugin = createConstEnum({
  SHIPPO: 'shippo',
  SHIPPO_FREE: 'shippo_free',
});
export type ShippingPlugin = EnumType<typeof ShippingPlugin>;

export const ImageStyle = createConstEnum({
  WIDTH_600: 'width_600',
  PORTRAIT_600x800: '3_4_sc_600x800',
  LANDSCAPE_800x600: '4_3_sc_800x600',
  ARTICLE_800: '4_1_5_sc_800x300',
  ARTICLE_1600: '4_1_5_sc_1600x600',
  LARGE: 'large',
  PRODUCT_PRIMARY: 'product_primary',
});

export type ImageStyle = EnumType<typeof ImageStyle>;

export const MetatagTag = createConstEnum({
  LINK: 'link',
  META: 'meta',
});
export type MetatagTag = EnumType<typeof MetatagTag>;

/**
 * for reference to generate enums arr, then copy value as json_encode
 *
 * $terms = Drupal::entityTypeManager()
 *       ->getStorage('taxonomy_term')
 *       ->loadByProperties(['vid' => 'non_instrument_product_types']);
 *
 *     $arr = [];
 *     foreach ($terms as $term) {
 *       $label = Strings::toSnakeCase($term->label());
 *       $arr[strtoupper($label)] = $term->uuid();
 *     }
 */
export const NonInstrumentProductTypeTerm = createConstEnum({
  ACCESSORIES: '0e9c59a9-7259-40cf-a7ea-066411d39d13',
  BLOCKS: '9cb84950-b98b-49e5-a0ef-a95d8c944b94',
  BOOKS: '676f092f-e126-4943-8a4a-65ae818943a7',
  BOW_PARTS: '12070a58-439e-48b2-85c8-22702486f2f5',
  REPAIR_RESTORATION_INSTRUMENT: '563cbcff-73c8-4065-b197-cf80bd958782',
  BRIDGES: 'ac6e1a6e-9ab3-44b0-b87b-57a44760c30b',
  INLAY: 'b39ede7f-b848-413d-be49-5f831ae87006',
  CHIN_RESTS: '96cb743a-8c4a-47d8-9137-f1ef67ee0126',
  MATERIALS: '192609a6-9755-4905-8d76-e8c68c1429f3',
  RIBS: 'ce1d6cc6-2500-4d7b-8f27-1b2f55f441ef',
  FINGERBOARDS: '8946aa6d-114e-4000-8208-81f15a188672',
  TONEWOODS: '36cb9753-57f6-4873-a9e9-a8a026549a0f',
  FITTING_SETS: '051f33c0-f3a0-4e17-b609-55372f5ed25f',
  PARTS: '3119e4d9-97dc-44be-8eb6-462ad98f7c84',
  VARNISH_SUPPLIES: 'e049754e-10aa-4d01-99b0-587c1635eb98',
  NUTS_SADDLES: '25128422-4a34-41b2-a776-d3a040a6781d',
  PEGS: '41d80e8c-e88c-49f7-b523-9b374b90afd1',
  PLANS_POSTERS: '68377fb3-0211-446c-9819-f20e92d4bb02',
  TAIL_PIECES: '8f0e9206-8847-4ee6-b776-01873195e299',
  MISC_PARTS: '3fa40333-1446-4d33-a3bf-dee247582d37',
  ROUTER_TEMPLATES: '6012a7e1-633b-4663-8cca-afedef94f325',
  OTHER: 'f895cbe0-4023-42b0-85c5-54de40731f49',
  TOOLS: '4e024f75-ec71-4ca6-9c42-d25ebc5f0789',
});

export type NonInstrumentProductTypeTerm = EnumType<typeof NonInstrumentProductTypeTerm>;

export const FittingsTerms = [
  NonInstrumentProductTypeTerm.CHIN_RESTS,
  NonInstrumentProductTypeTerm.FITTING_SETS,
  NonInstrumentProductTypeTerm.NUTS_SADDLES,
  NonInstrumentProductTypeTerm.PEGS,
  NonInstrumentProductTypeTerm.TAIL_PIECES,
  NonInstrumentProductTypeTerm.MISC_PARTS,
  NonInstrumentProductTypeTerm.FINGERBOARDS,
];

export const CookieName = createConstEnum({
  UPDATE_ROLES: 'directive__roles_updated',
});

export type CookieName = EnumType<typeof CookieName>;

// @see https://github.com/google/libphonenumber/blob/d3d6ffa7eb81c96c47ae3c2ea2c40f750b2cbbdf/javascript/i18n/phonenumbers/phonenumberutil.js#L1111
export const IntlPhoneValidationError = createConstEnum({
  /** The number length matches that of valid numbers for this region. */
  IS_POSSIBLE: 0,
  /** The number has an invalid country calling code. */
  INVALID_COUNTRY_CODE: 1,
  /** The number is shorter than all valid numbers for this region. */
  TOO_SHORT: 2,
  /** The number is longer than all valid numbers for this region. */
  TOO_LONG: 3,
  /**
   * The number length matches that of local numbers for this region only (i.e.
   * numbers that may be able to be dialled within an area, but do not have all
   * the information to be dialled from anywhere inside or outside the country).
   */
  IS_POSSIBLE_LOCAL_ONLY: 4,
  /**
   * The number is longer than the shortest valid numbers for this region,
   * shorter than the longest valid numbers for this region, and does not itself
   * have a number length that matches valid numbers for this region.
   * This can also be returned in the case where
   * isPossibleNumberForTypeWithReason was called, and there are no numbers of
   * this type at all for this region.
   */
  INVALID_LENGTH: 5,
});

export type IntlPhoneValidationError = EnumType<typeof IntlPhoneValidationError>;
