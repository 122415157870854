<template>
  <BaseCard
    :title="title"
    :hx="hx"
    :img-src="imgSrc"
    :href="href"
  >
    <template
      v-if="!published"
      #mastheadTop
    >
      <TagPill class="pill" />
    </template>

    <template
      v-if="isMaker || isRestorer || hasVideo || hasCertificate"
      #mastheadBottom
    >
      <div class="badges">
        <HandcraftedIcon
          v-if="isMaker"
          class="badge"
        />
        <RestorerIcon
          v-if="isRestorer"
          class="badge"
        />
        <VideoIcon
          v-if="hasVideo"
          class="badge"
        />
        <CertifiedIcon
          v-if="hasCertificate"
          class="badge"
        />
      </div>
    </template>

    <footer>
      <div
        v-if="storeName"
        class="byline"
      >
        {{ storeName }}
      </div>
      <div class="prices">
        <template
          v-if="price"
        >
          <div class="price">
            {{ price }}
          </div>
        </template>
        <template v-else-if="blurPrice">
          <div class="blur-price" />
          <span>$</span><span class="blur">{{ blurPrice }}</span>
        </template>
      </div>
    </footer>
  </BaseCard>
</template>

<script setup lang="ts">

const props = defineProps({
  imgSrc: {
    required: true,
    type: String,
  },
  hx: {
    required: true,
    type: Number,
  },
  title: {
    required: true,
    type: String,
  },
  href: {
    required: true,
    type: String,
  },
  price: {
    required: false,
    type: String,
    default: null,
  },
  blurPrice: {
    required: false,
    type: String,
    default: null,
  },
  listPrice: {
    required: false,
    type: String,
    default: null,
  },
  storeName: {
    required: false,
    type: String,
    default: null,
  },
  storeId: {
    required: false,
    type: String,
    default: null,
  },
  isMaker: {
    required: true,
    type: Boolean,
    default: false,
  },
  isRestorer: {
    required: true,
    type: Boolean,
    default: false,
  },
  hasVideo: {
    required: true,
    type: Boolean,
  },
  hasCertificate: {
    required: true,
    type: Boolean,
  },
  published: {
    required: true,
    type: Boolean,
  },
  linkToPreview: {
    required: false,
    type: Boolean,
    default: false,
  },
});

const href = props.linkToPreview
  ? `${props.href}?preview`
  : props.href;
const blurPrice = props.blurPrice?.replace(/[\d$]/g, 'X');
</script>

<style scoped lang="scss">
footer {
  font-size: var(--text-xs)
}

.price {
  //font-size: var(--text-std);
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: var(--space-xxxs);

  &--list {
    text-decoration: line-through
  }
}

.badges {
  display: flex;
  padding: .25em;
  margin: var(--space-xxxs);
  border-radius: 100em;
  background: white;
}

.badge {
  height: 1em;
  width: 1em;
  color: var(--color--bg-dark)
}

.pill {
  margin: var(--space-xs);
}

:deep(.masthead) {
  aspect-ratio: 1/1;
}

:deep(.masthead__inner) {
  height: 100%;
  display: flex;
  align-items: center;
  background-color: #F2F2F2;
}

:deep(.masthead__top),
:deep(.masthead__bottom) {
  z-index: 2;
}

:deep(.masthead__inner img) {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.blur {
  filter: blur(3.5px)
}
</style>
