<template>
  <div class="category-sections">
    <CategorySection
      class="featured"
      :heading="{text: 'Sustainably Focused'}"
    >
      <router-link
        to="/stores/figaro-importers"
        class="card card--nero"
      >
        <div class="copy copy--nero">
          <div>
            <span>
              Ebony Alternatives
            </span>
            <span>
              by Nero
            </span>
          </div>
        </div>
        <div class="img-wrapper img-wrapper--nero">
          <img
            :src="imgNero"
          >
        </div>
      </router-link>

      <router-link
        to="/stores/coruss"
        class="card card--coruss"
      >
        <div class="copy copy--coruss">
          <div>
            <span>
              Vegan Bows
            </span>
            <span>
              by Coruss
            </span>
          </div>
        </div>
        <div class="img-wrapper img-wrapper--coruss">
          <img
            :src="imgCorussBow"
          >
        </div>
      </router-link>
    </CategorySection>

    <CategorySection
      class="tools"
      :heading="{text: toolsSuppliesListing.title, href: toolsSuppliesListing.href}"
      :footer-link="{text: toolsSuppliesListing.title, href: toolsSuppliesListing.href}"
    >
      <ProductListing
        class="listing"
        :bundles-params="toolsSuppliesListing.bundlesParams"
        :limit="4"
        :show-filters="false"
        :show-results-count="false"
        :max-cols="4"
      >
        <template #productInsert>
          <li>
            <PhotographyStandProductCard />
          </li>
        </template>
      </ProductListing>
    </CategorySection>

    <CategorySection
      class="instruments-cats"
      :heading="{text: 'Instruments & Bows'}"
    >
      <BaseCard
        :img-src="imgViolin"
        :hx="3"
        title="Violins"
        href="/products/instruments#violins"
      />
      <BaseCard
        :img-src="imgViola"
        :hx="3"
        title="Violas"
        href="/products/instruments#violas"
      />
      <BaseCard
        :img-src="imgCello"
        :hx="3"
        title="Cellos"
        href="/products/instruments#cellos"
      />
      <BaseCard
        :img-src="imgCorussBow"
        :hx="3"
        title="Bows"
        href="/products/bows"
      />
    </CategorySection>

    <CategorySection
      class="authenticated"
      :heading="{text: 'With Certificates of Authenticity'}"
    >
      <BaseCard
        :img-src="imgAuthInstruments"
        :hx="3"
        title="Authenticated Instruments"
        href="/products/instruments?authenticated=true"
      />
      <BaseCard
        :img-src="imgAuthBows"
        :hx="3"
        title="Authenticated Bows"
        href="/products/bows?authenticated=true"
      />
    </CategorySection>

    <CategorySection
      class="instruments"
      :heading="{text: instrumentListing.title, href: instrumentListing.href}"
      :footer-link="{text: instrumentListing.title, href: instrumentListing.href}"
    >
      <ProductListing
        class="listing"
        :bundles-params="instrumentListing.bundlesParams"
        :limit="4"
        :show-filters="false"
        :show-results-count="false"
        :max-cols="4"
      />
    </CategorySection>

    <CategorySection
      class="bows"
      :heading="{text: bowsListing.title, href: bowsListing.href}"
      :footer-link="{text: bowsListing.title, href: bowsListing.href}"
    >
      <ProductListing
        class="listing"
        :bundles-params="bowsListing.bundlesParams"
        :limit="4"
        :show-filters="false"
        :show-results-count="false"
        :max-cols="4"
      />
    </CategorySection>

    <CategorySection
      class="fittings"
      :heading="{text: fittingsListing.title, href: fittingsListing.href}"
      :footer-link="{text: fittingsListing.title, href: fittingsListing.href}"
    >
      <ProductListing
        class="listing"
        :bundles-params="fittingsListing.bundlesParams"
        :limit="4"
        :show-filters="false"
        :show-results-count="false"
        :max-cols="4"
      />
    </CategorySection>
  </div>
</template>

<script setup lang="ts">
import { DrupalJsonApiParams } from 'drupal-jsonapi-params';
import { ProductType } from '~/composables/api/commerce/product';
import { FittingsTerms, NonInstrumentProductTypeTerm } from '~/types/enum/enum';
import imgAuthBows from '~/assets/homepage/authenticated--bows.png';
import imgAuthInstruments from '~/assets/homepage/authenticated--instruments.png';

import imgCorussBow from '~/assets/homepage/coruss-bow.png';
import imgNero from '~/assets/homepage/nero-peg.png';
import { useApiBaseUrlStore } from '~/store/api-base-url';

const apiBaseUrlStore = useApiBaseUrlStore();

const imgViolin = apiBaseUrlStore.backendUrl('/sites/default/files/styles/sc_600x600/public/2025-04/Violin_0.png');
const imgViola = apiBaseUrlStore.backendUrl('/sites/default/files/styles/sc_600x600/public/2025-04/Viola_0.png');
const imgCello = apiBaseUrlStore.backendUrl('/sites/default/files/styles/sc_600x600/public/2025-04/Cello_0.png');

const instrumentListing = {
  id: 'instruments-listing',
  title: 'Browse All Instruments',
  bundlesParams: [{
    bundles: [
      ProductType.INSTRUMENT,
    ],
    params: new DrupalJsonApiParams()
      .addFilter('status', '1'),
  }],
  href: '/products/instruments',
  linkText: 'View all instruments',
};

const bowsListing = {
  id: 'bows-listing',
  title: 'Browse All Bows',
  bundlesParams: [{
    bundles: [
      ProductType.BOW,
    ],
    params: new DrupalJsonApiParams()
      .addFilter('status', '1'),
  }],
  href: '/products/bows',
  linkText: 'View all bows',
};

const toolsSuppliesListing = {
  id: 'tools-listing',
  title: 'Browse Tools & Supplies',
  bundlesParams: [{
    bundles: [
      ProductType.NON_INSTRUMENT,
    ],
    params: new DrupalJsonApiParams()
      .addFilter('status', '1')
      .addInclude(['field_product_type'])
      .addFilter('field_product_type.id', [
        NonInstrumentProductTypeTerm.ACCESSORIES,
        ...FittingsTerms,
      ], 'NOT IN'),
  }],
  href: '/products/luthier-supplies',
  linkText: 'View all tools & supplies',
};

const fittingsListing = {
  id: 'fittings-listing',
  title: 'Browse All Fittings',
  bundlesParams: [{
    bundles: [
      ProductType.NON_INSTRUMENT,
    ],
    params: new DrupalJsonApiParams()
      .addFilter('status', '1')
      .addInclude(['field_product_type'])
      .addFilter('field_product_type.id', FittingsTerms, 'IN'),
  }],
  href: '/products/fittings',
  linkText: 'View all fittings',
};
</script>

<style scoped lang="scss">
.category-sections {
  display: flex;
  flex-direction: column;
  gap: var(--space-lg);
  margin-block: var(--space-md);

  @media (min-width: 600px) {
    gap: var(--space-xl);
  }

  .section {
    margin: 0 auto;
    width: 100%;
  }

  :deep(.section__heading) {
    @media(max-width: 700px) {
      font-size: var(--text-heading--h3)
    }
  }
}

:deep(.listing) {
  min-height: 0;

  li:nth-child(n+3) {
    display: none;
  }

  ul {
    @media(min-width: 350px) and (max-width: 449px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media(min-width: 350px) {
      gap: var(--space-sm);
    }
  }

  @media (min-width: 550px) {
    ul {
      gap: var(--space-md);
    }

    li:nth-child(n+3) {
      display: revert;
    }

    li:nth-child(n+4) {
      display: none;
    }
  }

  @media (min-width: 1030px) {
    li:nth-child(n+4) {
      display: revert;
    }

    li:nth-child(n+5) {
      display: none;
    }
  }
}

.featured :deep(.section__contents) {
  --cols: 1;
  display: grid;
  grid-template-columns: repeat(var(--cols), 1fr);
  gap: var(--space-sm);

  @media (min-width: 550px) {
    gap: var(--space-md);
  }

  @media(min-width: 1030px) {
    --cols: 2;
  }

  .card {
    $breakpoint--feature-card: 540px;

    &--nero {
      --accent-color: var(--color--bg-dark);

    }

    &--coruss {
      --accent-color: var(--color--on-light--purple);
    }

    display: flex;
    width: 100%;
    position: relative;
    border-radius: var(--border-radius--standard);
    overflow: hidden;
    transition: .3s;
    color: white;

    &:hover {
      filter: none;
      box-shadow: var(--box-shadow);
    }

    .copy {
      &--nero {
        background: linear-gradient(to right, var(--color--bg-dark--alt), var(--color--bg-dark));
      }

      &--coruss {
        background: linear-gradient(to right, var(--color--on-light--teal), var(--color--on-light--purple));
      }

      font-weight: 600;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      font-size: 1.1em;
      flex: 1;
      z-index: 2;
      padding-inline: 1em;

      @media(min-width: #{$breakpoint--feature-card}) {
        font-size: 1.5em;
        padding-inline: 2em 1em;

      }

      span {
        display: block;

        &:last-child {
          font-weight: normal;
          font-size: .8em;
        }
      }
    }

    .img-wrapper {
      flex-shrink: 0;
      position: relative;

      &--nero {
        padding-left: 5%;
        background: #B4B2AE;

        &::after {
          content: '';
          width: 2.5em;
          height: 100%;
          background: linear-gradient(to right, #B4B2AE, transparent);
          position: absolute;
          left: 0;
          top: 0;
        }
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100px;
        background-image: radial-gradient(circle 100px at right center, transparent 99px, var(--accent-color) 100px);
        z-index: 1;
        pointer-events: none;
      }
    }

    img {
      height: 5em;
      width: auto;
      display: block;
      @media(min-width: #{$breakpoint--feature-card}) {
        height: 8em;
      }
    }
  }

}

.instruments-cats :deep(.section__contents) {
  display: grid;
  grid-template-columns: repeat(var(--cols, 2), 1fr);
  gap: var(--space-sm);

  @media (min-width: 550px) {
    gap: var(--space-md);
  }
  @media(min-width: 1030px) {
    --cols: 4;
  }

  .masthead {
    aspect-ratio: 1/1;
  }

  .masthead__inner {
    height: 100%;
    display: flex;
    align-items: center;
    background-color: #F2F2F2;
  }

  .masthead__top,
  .masthead__bottom {
    z-index: 2;
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: contain;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  .title {
    position: relative !important;
    background: linear-gradient(to right, var(--color--bg-dark--alt), var(--color--bg-dark));
  }

}

.authenticated :deep(.section__contents) {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--space-sm);

  @media (min-width: 550px) {
    gap: var(--space-md);
  }

  article {
    display: flex;
    flex-direction: column;
  }

  .wrapper {
    &:hover, &:focus {
      img {
        transition: all ease .5s;
        transform: scale(1.05)
      }
    }

    &:last-child .title {
      background: linear-gradient(to right, var(--color--on-light--purple), var(--color--on-light--teal));
    }
  }

  .title {
    background: linear-gradient(to right, var(--color--on-light--teal), var(--color--on-light--purple));
    min-height: 61px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;

    // The images are cropped awkwardly so using abs position for now on desktoip
    @media(max-width: 1030px) {
      position: relative !important;
      margin-top: auto !important;
    }
  }
}

.authenticated :deep(.section__contents),
.instruments-cats :deep(.section__contents) {

  .wrapper {
    overflow: hidden;
    border-radius: var(--border-radius--standard);
    border: 1px var(--color--border) solid;

    &:hover, &:focus {
      box-shadow: var(--box-shadow);
      text-decoration: none;

      .title {
        text-decoration: none;
      }
    }

    article {
      position: relative;
      height: 100%;
    }

    img {
      //padding: var(--space-sm);
    }

    .masthead {
      margin-bottom: 0;
    }

    .title {
      position: absolute;
      bottom: 0;
      font-size: var(--text-lg);
      padding: var(--space-xxs) var(--space-md);
      color: white;
      width: 100%;
      text-align: center;
      text-decoration: none;
      z-index: 2;
      margin-top: 0;

      @media(min-width: 1030px) {
        font-size: var(--text-heading--h3);
        padding-inline: var(--space-xs);
      }
    }

  }
}
</style>
