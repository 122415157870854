<template>
  <span>
    <Menu>
      <MenuButtonChevron>
        Product Categories
      </MenuButtonChevron>
      <MountedTeleport to="#the-mobile-menu-expanded-container">
        <MenuItems>
          <MenuItem
            v-for="link in links"
            :key="link.href"
            v-slot="{ active }"
            as="template"
          >
            <router-link
              :to="link.href"
              :class="{ 'focus': active}"
            >
              {{ link.text }}
            </router-link>
          </MenuItem>
        </MenuItems>
      </MountedTeleport>
    </Menu>
  </span>
</template>

<script setup lang="ts">
import { Menu, MenuItem, MenuItems } from '@headlessui/vue';
import MountedTeleport from '~/components/utility/MountedTeleport.vue';
import MenuButtonChevron from '~/components/menu/MenuButtonChevron.vue';
import { link } from '~/common/link';

const links = [
  link('Instruments', '/products/instruments'),
  link('Bows', '/products/bows'),
  link('Fittings', '/products/fittings'),
  link('Tools & Supplies', '/products/luthier-supplies'),
  // link('Accessories', '/products/accessories'),
];
</script>

<style scoped lang="scss">
a {
  color: white;
}

[id^=headlessui-menu-items] {
  // Not sure why, but if this is set to 1px then it actually
  // renders as wider than the other menu borders.
  border-top: solid .5px var(--color--border);
  padding-bottom: var(--space-xxxs);
}

</style>
