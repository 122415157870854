<template>
  <section
    class="section"
  >
    <template v-if="heading.href">
      <router-link
        class="section__link"
        :to="heading.href"
      >
        <HeadingHx
          :hx="headingLevel"
          class="h2 section__heading"
        >
          <span class="section__title">
            {{ heading.text }}
          </span>
          <Icon
            class="section__linkIcon"
            icon="ph:arrow-right-bold"
          />
        </HeadingHx>
      </router-link>
    </template>
    <template v-else>
      <HeadingHx
        :hx="headingLevel"
        class="h2 section__heading"
      >
        <span class="section__title">
          {{ heading.text }}
        </span>
      </HeadingHx>
    </template>

    <div class="section__contents">
      <slot />
    </div>

    <template v-if="footerLink">
      <div class="section__footer">
        <router-link :to="footerLink.href">
          {{ footerLink.text }}
        </router-link>
      </div>
    </template>
  </section>
</template>

<script setup lang="ts">
import { Icon } from '@iconify/vue';

const props = defineProps<{
  heading: {
    text: string
    level?: number,
    href?: string,
  }
  footerLink?: {
    text: string,
    href: string
  }
}>();

const headingLevel = props.heading.level ?? 2;

</script>

<style scoped lang="scss">
.section {
  $blade-desktop-breakpoint: 1030px;

  margin: var(--space-lg) auto;
  max-width: var(--width--blade-max--1-col);

  @media(min-width: $blade-desktop-breakpoint) {
    max-width: 61em;

    &__footer {
      display: none
    }
  }

  &__link {
    color: var(--color--copy-primary);
  }

  &__heading {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    gap: var(--space-xxxs);
    transition: gap ease-in-out .2s;

    &:hover,
    &:focus {
      gap: var(--space-xs)
    }
  }

  &__contents {
    margin-top: var(--space-sm);
  }

  &__linkIcon {
    font-size: .55em;
  }

  &__footer {
    margin-top: var(--space-sm);
  }
}
</style>
