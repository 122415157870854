import './styles/main.css';
import 'vue-global-api';

import VueLazyLoad from 'vue3-lazyload';
import viteSSR, { ClientOnly } from 'vite-ssr';
import { createHead } from '@vueuse/head';
import generatedRoutes from 'virtual:generated-pages';
import { setupLayouts } from 'virtual:generated-layouts';
import { createPinia } from 'pinia';
import devalue from '@nuxt/devalue';
import { createInput, defaultConfig, plugin } from '@formkit/vue';
import { RouteLocationNormalized } from 'vue-router';
import { createDynamicForms } from '@asigloo/vue-dynamic-forms';
// import 'vue-google-maps-community-fork/src/main.js';
import App from './App.vue';
import { DEFAULT_LOCALE, extractLocaleFromPath, installI18n } from './i18n';
import config from '~/formkit.config';
import { removeTrailingChar } from '~/common/string';
import 'intl-tel-input/styles';
import IntlTelInput from './components/form/formkit-fields/AbstractIntlTelInput.vue';
import { createGtm } from '@gtm-support/vue-gtm';

const routes = setupLayouts(generatedRoutes);

// https://github.com/frandiox/vite-ssr
export default viteSSR(
  App,
  {
    routes,
    // routes,
    // @todo: update vite-ssr and deps to get this working
    routerOptions: {
      /**
       * This seems to sometimes fire and others not. Not totally sure
       * what situation wold cause this not to work, tho. At time of writing,
       * e.g. navigating between catchall routes like /legal/foo to
       * /legal/bar doesn't scroll. Sometimes going between LP pages
       * scrolls and others it doesn't.
       * @param to
       * @param from
       * @param savedPosition
       */
      scrollBehavior(to, from, savedPosition) {
        const isProductPage = to.name
          && String(to.name)
            .startsWith('products-');

        const isLandingPage = to.path.includes('/lp')
          || to.name === 'lp-subpath';

        // If the URL contains '/lp' or the route name is 'lp-subpath', scroll to the top.
        if (isProductPage || isLandingPage) {
          return { top: 0 };
        }

        return to.hash
          ? { el: to.hash }
          : savedPosition
            ? {
              ...savedPosition,
              behavior: 'instant', // Make sure the saved position scroll is instant
            }
            : { top: 0 };
      },
    },

    // Use Router's base for i18n routes
    base: ({ url }) => {
      const locale = extractLocaleFromPath(url.pathname);
      return locale === DEFAULT_LOCALE
        ? '/'
        : `/${locale}/`;
    },

    // https://pinia.esm.dev/ssr/#state-hydration
    transformState: (state) => (import.meta.env.SSR
      ? devalue(state)
      : state),
  },
  async (ctx) => {
    // install all modules under `modules/`
    Object.values(import.meta.globEager('./modules/*.ts'))
      .map((i) => i.install?.(ctx));

    const {
      app,
      url,
      router,
      isClient,
      initialState,
      initialRoute,
    } = ctx;

    // https://stackoverflow.com/a/68688746/1471267
    app.config.globalProperties.$log = console.log;

    const VueDynamicForms = createDynamicForms({});
    app.use(VueDynamicForms);

    // FormKit
    const intlTelInput = createInput(IntlTelInput, {
      props: ['country'],
    });
    app.use(plugin, {
      ...defaultConfig({
        ...config,
        inputs: {
          intlTel: intlTelInput,
        },
      }),

    });

    app.use(VueLazyLoad);

    const head = createHead();
    app.use(head);

    app.use(
      createGtm({
        id: 'GTM-TR2DKHFN',
      }),
    );

    const pinia = createPinia();
    app.use(pinia);

    // @see https://pinia.esm.dev/ssr/#state-hydration
    // after rendering the page, the root state is build and can be read directly
    // on `pinia.state.value`.

    // serialize, escape (VERY important if the content of the state can be changed
    // by the user, which is almost always the case), and place it somewhere on
    // the page, for example, as a global variable.

    devalue(pinia.state.value);

    app.component(ClientOnly.name, ClientOnly);

    // Load language asyncrhonously to avoid bundling all languages
    await installI18n(app, extractLocaleFromPath(initialRoute.href));

    // Freely modify initialState and it will be serialized later
    if (import.meta.env.SSR) {
      // This object can be passed to Vuex store
      // initialState.test = 'This should appear in page-view-source'

      // `isClient` here is a handy way to determine if it's SSR or not.
      // However, it is a runtime variable so it won't be tree-shaked.
      // Use Vite's `import.meta.env.SSR` instead for tree-shaking.
      // This copied from the router instead of shared because not sure
      // if sharing the var will break things.

      // The ctx.url var seems to be unreliable when our local site is served
      // over https via https://www.npmjs.com/package/vite-plugin-mkcert.
      // Seems to cause the origin.url to be seen as "http://undefined,"
      // so we have a manual override for this case.
      initialState.originUrl = url.origin.includes('undefined')
        ? 'https://localhost'
        : url.origin;

      // Not sure if this is the "proper" way to do this, but it seems
      // like one approach to populate the initial value of a pinia
      // store so that it can persist and be rehydrated from SSR.
      pinia.state.value.apiBaseUrl = {
        applicationBaseUrl: initialState.originUrl,
      };

      // this will be stringified and set to window.__INITIAL_STATE__
      initialState.pinia = pinia.state.value;
    } else {
      // In browser, initialState will be hydrated with data from SSR
      // console.log('Initial state:', initialState);
      // on the client side, we restore the state
      pinia.state.value = initialState.pinia;
      // console.log('hydrated pinia: ', pinia.state.value);
    }

    router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
      // Redirect paths with trailing slashes to the version without a trailing slash.
      //
      // For some reason the first load of the app always ends up sending
      // to the trailing slash version (e.g. /legal/ instead of just /legal)
      // causing catchall routes to trigger before the desired component.
      // In theory, it sounds like we should be able to handle this with the
      // strict routerOption, but that does not actually work.
      if (to.path !== '/' && to.path.endsWith('/')) {
        return {
          path: removeTrailingChar(to.path, '/'),
          // It's important to ensure that query is persisted.
          query: to.query,
          replace: true,
        };
      }

      /**
       * Redirects launch landing pages to the dynamic route versions.
       *
       * Note that these redirects are/should also be defined in our
       * server index.js. Additional redirects also need to go in there.
       */
      const getLandingPageRedirect = (path: string) => ([
        '/become-a-seller',
        '/invoicing',
        '/home-trials',
        '/omo',
        '/wholesale',
      ].includes(path)
        ? `/lp${path}`
        : null);
      const landingPageRedirectPath = getLandingPageRedirect(to.href || to.path);

      if (landingPageRedirectPath) {
        return {
          path: landingPageRedirectPath,
          query: to.query,
          replace: true,
        };
      }

      const standardRedirects = [
        {
          from: '/products/instruments-bows',
          to: '/products/instruments',
        },
      ];

      const standardRedirect = standardRedirects.find((x) => x.from === to.path);
      if (standardRedirect) {
        return {
          path: standardRedirect.to,
          query: to.query,
          replace: true,
        };
      }
    });

    return { head };
  },
);
