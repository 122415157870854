<template>
  <button
    class="pill"
    :aria-pressed="isActive"
    @click="toggleIsActiveAndEmit"
  >
    <slot />
  </button>
</template>

<script setup lang="ts">
import {
  defineEmits, defineProps, ref, watch,
} from 'vue';

const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['toggle']);

// Use a local reactive state that reflects the external "active" prop.
const isActive = ref(props.active);

// Watch for external changes to "active" so that the internal state stays in sync.
watch(
  () => props.active,
  (newVal) => {
    isActive.value = newVal;
  },
);

// When the button is clicked, toggle the state and emit the new value.
const toggleIsActive = () => {
  isActive.value = !isActive.value;
};

const toggleIsActiveAndEmit = () => {
  toggleIsActive();
  emit('toggle', isActive.value);
};
</script>

<style scoped lang="scss">
.pill {
  border: solid 1px var(--color--border);
  border-radius: 100px;
  font-size: var(--text-sm);
  padding: var(--space-xxs) var(--space-std);
  transition: all ease-in-out 0.25s;

  &:hover,
  &:focus {
    border-color: var(--color--copy-primary);
  }

  &[aria-pressed='true'] {
    background: var(--color--bg-dark);
    color: white;
  }
}
</style>
