import { useStorage } from '@vueuse/core';
import { Store } from 'pinia';
import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import { AuthResponse, GrantType, useOauth } from '~/composables/api/oauth';
import { GenericObject } from '~/types/types';
import { useDrupalRestApi } from '~/composables/api/drupal-rest-api';
import { useCartStore } from '~/store/cart';
import { usePiniaUtils } from '~/composables/pinia';
import { useApiBaseUrlStore } from '~/store/api-base-url';
import { EitherAxiosResponseData } from '~/composables/api/api-common';
import { delay } from '~/common/delay';
import { DrupalDate, DrupalRole } from '~/types/drupal-common';
import { JsonResponse } from '~/types/json-api/json-api';
import { deleteCookie } from '~/common/cookie';
import { CookieName } from '~/types/enum/enum';

/**
 * The store's state.
 */
interface AuthState {
  accessToken: string,
  refreshToken: string,
  tokenExpiration: number
  // Is suffixed with var since we have a getter
  // with the name didAutoLogout, and having a prop on
  // the store with the same name breaks things.
  didAutoLogoutVar: boolean,
  timer: number,
  drupalSessionName: string,
  drupalSessionId: string,
  _scopes: string,
  _roles: string
}

/**
 * Tokens and expiration associated with an authentication.
 *
 * The implementation of tokenExpiration throughout should
 * probably be updated so that it also sits in the AuthState,
 * which would allow that interface to extend this one.
 */
interface AuthMetdata {
  accessToken: string,
  refreshToken: string,
  tokenExpiration: number,
}

interface RegistrationResponse {
  changed: DrupalDate,
  commerce_remote_id: GenericObject[],
  created: DrupalDate,
  customer_profiles: GenericObject[],
  default_langcode: { value: boolean }[],
  langcode: { value: string }[][1],
  name: { value: string }[][1],
  uid: { value: string | number }[][1],
  user_picture: GenericObject[],
  uuid: { value: string }[][1]
}

export interface AuthorizationHeader {
  headers: {
    Authorization: string
  };
}

/**
 * Get an expiration date timestamp from an expire duration.
 *
 * @param {number} expiresIn - The expire duration in ms.
 */
const getExpirationDate = (expiresIn: number) => Date.now() + expiresIn;

/**
 * Gets an "expires in" value in milliseconds from an expiration timestamp.
 *
 * If a timestamp from the past is provided, then this will be negative,
 * indicating that the expiration time has already passed.
 */
const getExpiresIn = (tokenExpiration: number): number => tokenExpiration - Date.now();

/**
 * Gets an "expires in" value that occurs before the token expiration.
 *
 * In order to help avoid making requests with expired token, it can
 * be helpful to refresh the token some time before it expires as
 * opposed to exactly when it expires. It's not a problem if the token
 * expiration ends up being adjusted multiple times (in the timer and
 * in the store, for instance) because the only effect is that the
 * token might be refreshed even earlier.
 *
 * @param tokenExpiration
 */
const getAdjustedExpiresIn = (tokenExpiration: number) => {
  const expiresIn = getExpiresIn(tokenExpiration);
  const adjustmentSeconds: number = 30;
  return expiresIn - (adjustmentSeconds * 1000);
};

/**
 * Whether the access token expiration is in the past.
 * @param state
 */
const checkTokenIsExpired = (state: any) => {
  const tokenExpiration = Number(state.tokenExpiration);
  const expiresIn = getExpiresIn(tokenExpiration);
  return expiresIn <= 0;
};

const expiresSoonSeconds: number = 15;
const expiresSoonMilliseconds: number = expiresSoonSeconds * 1000;
const howLongUntilExpiration = (tokenExpiration: number): number => tokenExpiration - Date.now();
const tokenExpiresSoon = (tokenExpiration: number) => howLongUntilExpiration(tokenExpiration) < expiresSoonMilliseconds;

export type EitherAuthHeader = E.Either<string, AuthorizationHeader>;

export interface AuthStore extends Store {
  getAuthorizationHeader: () => Promise<EitherAuthHeader>;
}

/**
 * Ensures that the auth store is present before running a function.
 */
export const guardAuthStore = (a: AuthStore | null, successCb: (x: AuthStore) => Promise<E.Either<any, any>>, failCb?: () => Promise<E.Either<any, any>>): any => pipe(
  O.fromNullable(a),
  O.match(
    async () => pipe(
      O.fromNullable(failCb),
      O.match(
        async () => E.left(new Error('Auth store is null')),
        async (cb) => cb(),
      ),
    ),
    async (s: AuthStore) => successCb(s),
  ),
);

/**
 * Calls a function with the header derived from the guarded auth store.
 *
 * If the auth header failed, then an error is returned and the provided
 * function is not called. If you want to execute a fallback function when
 * there is an error with the header, use callWithOptionalAuthHeader().
 */
export const callWithAuthHeader = <T>(
  a: AuthStore | null,
  f: (x: AuthorizationHeader, validatedAuthStore?: AuthStore) => Promise<EitherAxiosResponseData<T>>,
): Promise<EitherAxiosResponseData<T>> => guardAuthStore(a, async (s) => pipe(
    await s.getAuthorizationHeader(),
    E.match(
      async (e) => E.left(new Error(e)),
      async (authHeader) => f(authHeader, a),
    ),
  ));

/**
 * Calls a function with the header derived from the guarded auth store.
 *
 * If the auth header fails, then a fallback function is executed. This
 * function exists solely to streamline DX, as it's essentially the same
 * as calling callWithAuthHeader() with the authCb(), and then running
 * the noAuthCb() if the result is E.isLeft().
 */
export const callWithOptionalAuthHeader = <T>(
  a: AuthStore | null,
  authCb: (x: AuthorizationHeader) => Promise<EitherAxiosResponseData<T>>,
  noAuthCb: () => Promise<EitherAxiosResponseData<T>>,
): Promise<EitherAxiosResponseData<T>> => guardAuthStore(
    a,
    async (s) => pipe(
      await s.getAuthorizationHeader(),
      E.match(
        async () => noAuthCb(),
        async (x) => authCb(x),
      ),
    ),
    noAuthCb,
  );

/**
 * Parses the contents of a JWT.
 *
 * This is not a secure way of parsing the JWT contents. What we
 * should really be doing is decoding it using the secret key
 * that was used to generate the token, etc, so that we can be
 * sure that the data in the token is not spoofed.
 *
 * However, since we are only using the information to determine
 * the scopes on the role for deciding very limited types of page
 * access, this isn't really a security concern. If this is used
 * more extensively then we should implement a more secured strategy.
 *
 * @param token
 */
const parseJwt = (token: string): {
  aud: string,
  exp: number,
  iat: number,
  jit: string,
  nbf: number,
  scope: DrupalRole[],
  sub: string
} => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+')
    .replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64)
    .split('')
    .map((c) => `%${(`00${c.charCodeAt(0)
      .toString(16)}`).slice(-2)}`)
    .join(''));

  return JSON.parse(jsonPayload);
};

/**
 * Initializes/implements the authentication store.
 */
export const useAuthStore = () => {
  const oauth = useOauth();
  const drupalRestApi = useDrupalRestApi();
  const cartStore = useCartStore();
  const apiBaseUrlStore = useApiBaseUrlStore();

  const pu = usePiniaUtils();
  return pu.initWindowDependentStore({
    id: 'authentication',
    state: (): AuthState => ({
      accessToken: useStorage<string>('accessToken', '', pu.maybeLocalstorage) as unknown as string,
      refreshToken: useStorage<string>('refreshToken', '', pu.maybeLocalstorage) as unknown as string,
      tokenExpiration: useStorage<number>('tokenExpiration', 0, pu.maybeLocalstorage) as unknown as number,
      didAutoLogoutVar: false,
      timer: 0,
      drupalSessionName: useStorage<string>('drupalSessionName', '', pu.maybeLocalstorage) as unknown as string,
      drupalSessionId: useStorage<string>('drupalSessionId', '', pu.maybeLocalstorage) as unknown as string,
      _scopes: useStorage<string>('scopes', '', pu.maybeLocalstorage) as unknown as string,
      _roles: useStorage<string>('roles', '', pu.maybeLocalstorage) as unknown as string,
    }),
    actions: {
      /**
       * Attempts user authorization via the /oauth/token endpoint.
       * If successful, saves the tokens, expiration, etc to the store.
       *
       * @param username - The authenticating username.
       * @param password - The authenticating password.
       * @param delayMs - The amount of time to delay before returning.
       */
      async login(username: string, password: string, delayMs: number = 1000): Promise<EitherAxiosResponseData<AuthResponse>> {
        return pipe(
          await oauth.doPost({
            grant_type: GrantType.PASSWORD,
            username,
            password,
          }),
          E.match(
            async (e) => E.left(e),
            async (data) => {
              // By default, it's seconds, so convert to ms
              const expiresIn = data.expires_in * 1000;
              const defaultTokenExpiration = getExpirationDate(expiresIn);
              const adjustedExpiresIn = getAdjustedExpiresIn(defaultTokenExpiration);
              const accessToken = data.access_token;

              this.startExpireTimer(adjustedExpiresIn);
              // We cannot actually do this because of circular dependencies.
              // Since we only have a single entry point to the application
              // at the moment, we'll just run this from the login form. Need
              // to figure out an alternative approach if additional entry
              // points are added.
              // if (cartApi) {
              //   // To ensure that the store gets populated.
              //   cartApi.getCarts(await cartApi.getCartToken());
              // }

              // console.log(data.refresh_token);
              this._setUser({
                accessToken,
                refreshToken: data.refresh_token,
                tokenExpiration: getExpirationDate(adjustedExpiresIn),
                session: data.session || undefined,
              });

              // We need to ensure that there is a delay present here, otherwise
              // the system will get ahead of simple_oauth/jwt which results in
              // errors when subsequent callers leverage the token, such as
              // when merging user carts immediately after login, etc.
              // This might be environment-dependent, where the delay should really depend
              // on things like the user's connection speed, etc, but not sure of how
              // to actually dynamically handle this kind of thing.
              if (delayMs > 0) {
                await delay(delayMs);
              }

              return E.right(data);
            },
          ),
        );
      },

      /**
       * Register a user account.
       */
      async register(username: string, email: string, password: string, roles: DrupalRole[] = []): Promise<EitherAxiosResponseData<RegistrationResponse>> {
        const regData = {
          name: username,
          mail: email,
          pass: password,
          roles,
        };

        return drupalRestApi.post<RegistrationResponse>('/api/register-user', regData);
      },

      /**
       * Refresh the access token with the refreshToken in the store.
       *
       * Note that the refresh token technically has an expiration time
       * that is also configured in simple_oauth, but it's long enough
       * that we don't need to account for it in all likelihood. Worst
       * case is that it is invalid and the refresh request fails,
       * leading to autologout.
       *
       * Also note that this does not set the expiration timer on the window.
       * The implementor needs to do that after refreshing the token.
       *
       * @return boolean - Whether the token was successfully refreshed.
       */
      async refreshAccessToken(): Promise<boolean> {
        if (!this.refreshToken) {
          console.warn('Could not refresh access token because no refresh token is present.');
          return false;
        }

        const refreshed = pipe(
          await oauth.doPost({
            grant_type: GrantType.REFRESH_TOKEN,
            refresh_token: this.refreshToken || '',
          }),
          E.match(
            (e) => {
              console.log('Could not refresh access token.');
              // Leave this commented out. For some reason, logging this
              // appearing to halt further execution.
              // errorResolver.logError(eitherResponse.left);
              return E.left(e);
            },
            (data) => {
              const expiresIn = data.expires_in * 1000;
              const defaultTokenExpiration = getExpirationDate(expiresIn);
              const adjustedExpiresIn = getAdjustedExpiresIn(defaultTokenExpiration);
              const tokenExpiration = getExpirationDate(adjustedExpiresIn);

              this._setUser({
                accessToken: data.access_token,
                refreshToken: data.refresh_token,
                tokenExpiration,
                // userId: data.localId,
              });

              // console.log('data from refresh response', data);
              // console.log('tokens in store after refresh: ', this.accessToken, this.refreshToken);

              this.startExpireTimer(tokenExpiration);
              return E.right(data);
            },
          ),
        );

        return E.isRight(refreshed);
      },

      /**
       * Removes auth data from localStorage and the store.
       */
      async logout() {
        await callWithAuthHeader(
          this,
          // Need to pass an empty array for the request data simply because
          // this is a post request, but we don't actually have any data
          // to send along.
          async (h) => drupalRestApi.post<Promise<JsonResponse<unknown>>>('/api/logout', [], h),
        );

        clearTimeout(this.timer);
        this.tokenExpiration = 0;

        // Want to make sure that we only clear the cart token if it is
        // set to the value of the user's access token. This ensures that
        // an authenticated user's cart does not persist through logout,
        // but if this is somehow called when the user is anonymous then
        // it does not kill their cart session.
        if (cartStore && (cartStore.cartToken === this.accessToken)) {
          cartStore.deleteToken();
          cartStore.carts = [];
        }

        this._unsetUser();
      },

      /**
       * Dispatches the logout event and sets the autoLogout prop in the store.
       */
      autoLogout() {
        this.logout();
        this._setAutoLogout();
      },

      /**
       * Start the expire timer with a given expiration duration in ms.
       *
       * Normally we'd just define this as an anonymous function outside
       * of this object, but since actions live on "this", we don't have
       * a way to execute them from outside the object.
       *
       * We could just define all action methods as arrow functions,
       * but at the time of writing that seems to throw "object may not
       * exist" errors when calling other action methods on "this".
       * May be fine and just need to try it out.
       *
       * @param tokenExpiration
       */
      startExpireTimer(tokenExpiration: number) {
        const expiresIn = getAdjustedExpiresIn(tokenExpiration);
        // console.log('starting timer, expiresIn: ', expiresIn / 1000);
        this.timer = window.setTimeout(async () => {
          try {
            console.log('trying refresh from expire timer');
            await this.refreshAccessToken();
          } catch (e) {
            // This is aggressive in that any error logs out.
            // It may be better for it to check what the error is,
            // but leaving it aggressive to start.

            // console.log('refresh failed, auto logout', e)
            this.autoLogout();
          }
        }, expiresIn);
      },

      /**
       * Builds the authorization header for the store.
       *
       * This method also refreshes the access token before constructing the header
       * to help ensure that requests don't hit access denied errors due to tokens
       * that expired in/near transit.
       */
      async getAuthorizationHeader(): Promise<EitherAuthHeader> {
        if (!this.accessToken) {
          return E.left('No valid access token on the store.');
        }

        if (tokenExpiresSoon(this.tokenExpiration)) {
          const refreshed = await this.refreshAccessToken();
          if (!refreshed) {
            return E.left('Failed to refresh the token.');
          }
        }

        // console.log('header access token', this.accessToken)
        // console.log('header refresh  token', this.refreshToken)

        return E.right(this._getHeader());
      },

      async updateRoles(userApi): Promise<EitherAxiosResponseData<DrupalRole[]>> {
        return pipe(
          await callWithAuthHeader(
            this,
            async (authHeader) => userApi.getRoles(authHeader),
          ),
          E.fold(
            (l) => E.left(l),
            (roles) => {
              this.setRoles(roles);
              deleteCookie(CookieName.UPDATE_ROLES, '/', apiBaseUrlStore.apiBaseDomain);
              return E.right(roles);
            },
          ),
        );
      },

      setRoles(roles: DrupalRole[]): DrupalRole[] {
        this._roles = roles.join(' ');
        return roles;
      },

      addRole(role: DrupalRole): DrupalRole[] {
        if (!this._roles.includes(role)) {
          this._roles = `${this._roles} ${role}`;
        }
        return this._roles.split(' ');
      },

      _getHeader() {
        return {
          headers: {
            Authorization: `Bearer ${this.accessToken}`,
          }
          ,
        };
      },

      /**
       * Saves the user auth info to the store.
       *
       * @param authMetadata - The auth metadata to be stored.
       */
      _setUser(authMetadata: AuthMetdata) {
        // state.userId = payload.userId
        this.didAutoLogoutVar = false;

        const { accessToken } = authMetadata;

        // If you decide to use this for anything other than very
        // basic page access checking based on scopes, see the docs
        // for the parseJwt() function to understand potential
        // security/escalation issues.
        const tokenDecoded = parseJwt(accessToken);

        this.accessToken = accessToken;
        this.refreshToken = authMetadata.refreshToken;
        this.tokenExpiration = authMetadata.tokenExpiration;
        this._scopes = tokenDecoded.scope.join(' ');

        // If _roles are not yet set, then it's safe to set them based
        // on the scopes. However, if they've already been overridden by
        // the frontend, then don't override them.
        //
        // Since oAuth scopes cannot be updated without requesting a new
        // token, we may have manually requested the roles in another way.
        // This might happen if the backend updated a user roles and set them to a cookie, and the frontend picked this up and updated _roles.
        //
        // Just like our note in parseJwt(), this is not a secure way of
        // handling this. However, again, we aren't using this for any critical
        // business logic. Just for  determining whether to show elements
        // or pages based on role. If security becomes an issue, this approach
        // becomes insufficient.
        if (!this._roles) {
          this.setRoles(tokenDecoded.scope);
        }

        // Use this.accessToken for the value just for consistency since that's
        // what's being used to unset the cookie. There shouldn't be any difference
        // between using the value directly and using the one on the store,
        // but just to be safe.
        //
        // @see Drupal\hc\EventSubscriber\OauthSSOSubscriber::doJwtLogin()
        document.cookie = `${this.jwtCookiePrefix};max-age=${authMetadata.tokenExpiration};secure;SameSite=None`;

        if (cartStore) {
          cartStore.setToken(accessToken);
        }
      },

      /**
       * Removes the user auth info from the store.
       */
      _unsetUser() {
        // Make sure to do this BEFORE unsetting this.accessToken.
        //
        // @see Drupal\hc\EventSubscriber\OauthSSOSubscriber::doJwtLogin()
        document.cookie = `${this.jwtCookiePrefix};expires=Thu, 01 Jan 1970 00:00:01 GMT`;

        this.accessToken = '';
        this.refreshToken = '';
        this._scopes = '';
        this._roles = '';
      },

      /**
       * Sets the autoLogout property in the store to true.
       */
      _setAutoLogout() {
        this.didAutoLogoutVar = true;
      },
    },
    getters: {
      jwtCookiePrefix: (state) => `frontend_jwt=${state.accessToken};domain=${apiBaseUrlStore.apiBaseDomain}`,

      /**
       * Returns whether the user is currently authenticated.
       *
       * @param state - The store state.
       */
      isAuthenticated: (state): boolean => !!(state.accessToken && state.refreshToken && state.tokenExpiration),

      // @ts-ignore
      scopes: (state): DrupalRole[] => (state._scopes === ''
        ? []
        : state._scopes.split(' ')),

      // @ts-ignore
      roles: (state): DrupalRole[] => (state._roles === ''
        ? []
        : state._roles.split(' ')),

      /**
       * Checks if the autoLogout was fired.
       *
       * @param state - The store state.
       */
      didAutoLogout: (state) => state.didAutoLogoutVar,

      /**
       * Whether the token is expiring soon.
       *
       * If it is then it may need to be refreshed.
       *
       * @param state - The store state.
       */
      tokenExpiresSoon: (state) => tokenExpiresSoon(state.tokenExpiration),

      /**
       * Whether the token is expired.
       *
       * @param state - The store state.
       */
      tokenIsExpired: (state) => checkTokenIsExpired(state),

      canViewPricing: (state) => () => [DrupalRole.TAX_EXEMPT, DrupalRole.ADMINISTRATOR, DrupalRole.VERIFIED].some((x) => state._roles.includes(x)),

      canPurchase: (state) => [DrupalRole.TAX_EXEMPT, DrupalRole.ADMINISTRATOR].some((x) => state._roles.includes(x)),
    },
  });
};
