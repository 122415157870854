<template>
  <div class="header">
    <PageContainer class="header__container">
      <section
        id="header"
        class="section section--header"
      >
        <BaseBranding :hide-name="true" />
        <nav class="nav nav--main">
          <UlUnlisted :inline="true">
            <li
              class="nav__li nav__li--productCategoryDropdown"
            >
              <DropdownMenuBase label="Product Categories">
                <MenuItem
                  v-for="(productCategoryLink, i) of productCategoryLinks"
                  :key="i"
                >
                  <router-link :to="productCategoryLink.href">
                    {{ productCategoryLink.text }}
                  </router-link>
                </MenuItem>
              </DropdownMenuBase>
            </li>

            <li
              v-for="(productCategoryLink, i) of productCategoryLinks"
              :key="i"
              class="nav__li nav__li--productCategory"
            >
              <router-link :to="productCategoryLink.href">
                {{ productCategoryLink.text }}
              </router-link>
            </li>

            <li
              v-for="(desktopLink, i) of desktopLinks"
              :key="i"
              class="nav__li nav__li--desktop"
            >
              <router-link :to="desktopLink.href">
                {{ desktopLink.text }}
              </router-link>
            </li>

            <li
              v-if="!isLoggedIn"
              class="nav__li nav__li--seller nav__li--pullRight"
            >
              <router-link to="/lp/become-a-seller">
                Become a Seller
              </router-link>
            </li>

            <li
              class="nav__li nav__li--user"
              :class="{'nav__li--account': isLoggedIn, 'nav__li--pullRight': isLoggedIn }"
            >
              <template v-if="isLoggedIn">
                <AccountDropdown />
              </template>
              <template v-else>
                <router-link to="/login">
                  Log In
                </router-link>
              </template>
            </li>

            <template v-if="isLoggedIn && canPurchase">
              <li class="nav__li nav__li--cart">
                <router-link
                  to="/cart"
                  alt="cart"
                  class="cart-link"
                >
                  <CartIcon />
                </router-link>
              </li>
            </template>
          </UlUnlisted>
        </nav>
      </section>
      <section class="section section--secondary">
        <nav class="nav nav--secondary">
          <TheMobileMenu />
        </nav>
      </section>
    </PageContainer>
  </div>
</template>

<script lang="ts" setup>
import { MenuItem } from '@headlessui/vue';

import { useAuthStore } from '~/store/auth';
import UlUnlisted from '~/components/utility/UlUnlisted.vue';
import BaseBranding from '~/components/logos/BaseBranding.vue';
import TheMobileMenu from '~/components/menu/TheMobileMenu.vue';
import AccountDropdown from '~/components/menu/dropdowns/AccountDropdown.vue';
import { link } from '~/common/link';
import DropdownMenuBase from '~/components/ui-misc/DropdownMenuBase.vue';

const authStore = useAuthStore();

const isLoggedIn = authStore
  ? computed(() => authStore.isAuthenticated)
  : false;

const canPurchase = computed(() => authStore?.canPurchase);

const productCategoryLinks = [
  link('Instruments', '/products/instruments'),
  link('Bows', '/products/bows'),
  link('Fittings', '/products/fittings'),
  link('Tools & Supplies', '/products/luthier-supplies'),
  // link('Accessories', '/products/accessories'),
];

const desktopLinks = [
  link('Sellers', '/stores'),
  link('Blog', '/blog'),
];
</script>

<style scoped lang="scss">
$breakpoint: 768px;
* {
  line-height: 1;
}

.header {
  background: var(--color--bg-dark);
  // Not sure exactly why, but we need this sometimes to ensure that the
  // account dropdown menu isn't hidden by page content.
  z-index: 10;

  @media(min-width: $breakpoint) {
    padding: var(--space-sm) 0;
  }

  &__container {
    @media(max-width: 500px) {
      padding: 0;
    }
  }
}

.section {
  display: flex;
  align-items: center;

  &--header {
    padding: var(--space-xxxs) 0 var(--space-xxxxs);
    @media(max-width: $breakpoint) {
      padding: var(--space-xxxxs) 0;
    }
  }

  &--secondary {
    border: 1px solid grey;
    border-left: none;
    border-right: none;

    @media(min-width: $breakpoint) {
      margin: 0 -2em;
      display: none;
    }

    @media(min-width: $breakpoint) {
    }
  }
}

a {
  color: white;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    filter: brightness(1);
  }

  @media(max-width: $breakpoint) {
    padding: var(--space-xs) 0;
    display: block;
  }
}

a,
button,
:deep(button) {
  font-size: var(--text-sm);
}

.nav {
  flex: 1;
  margin-left: var(--space-std);
  margin-top: -.15em;

  &--main {
    margin-right: var(--space-sm);
    @media(min-width: 501px) {
      margin-right: 0;
    }

    ul {
      justify-content: flex-end;
      gap: var(--space-std);

      @media(min-width: $breakpoint) {
        justify-content: revert;
      }
    }

    li {
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &--secondary {
    margin-left: 0;

    :deep(ul) {
      display: flex;
      justify-content: space-evenly;
    }
  }

  &__li {

    &--pullRight {
      margin-left: auto !important;
    }

    &--account {
      display: flex;
      align-items: center;

      a {
        padding: revert;
      }
    }

    &--productCategoryDropdown,
    &--productCategory,
    &--desktop {
      display: none;
    }

    &--productCategoryDropdown,
    &--desktop {
      @media(min-width: $breakpoint) {
        display: revert;
      }
    }

    @media (min-width: 1030px) {
      &--productCategoryDropdown {
        display: none;
      }

      &--productCategory {
        display: block;
      }
    }

  }
}

.cart-link {
  display: flex;
  align-items: center;

  &:hover,
  &:focus {
    color: var(--color--on-dark--orange);
  }
}

:deep(.logo) {
  @media(max-width: 500px) {
    transform: scale(1.75);
    margin: 0 .5em;
  }
}

:deep(.branding__name) {
  @media(max-width: 500px) {
    display: none;
  }
}

</style>
